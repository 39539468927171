import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

// Homepage
import { Home } from "@app/screens/Homepage/Home";
import { Docs } from "@app/screens/Homepage/Docs";
import { Terms } from "@app/screens/Homepage/Terms";
import { Privacy } from "@app/screens/Homepage/Privacy";
import { Pricing } from "@app/screens/Homepage/Pricing";
import { PageNotFound } from "@app/screens/Homepage/404";

// Auth
import { Login } from "@app/screens/Auth/Login";
import { Exchange } from "@app/screens/Auth/Exchange";
import { Agreement } from "@app/screens/Auth/Agreement";
import { BetaSignup } from "@app/screens/Auth/BetaSignup";

// Admin
import { Credentials } from "@app/screens/Admin/Credentials";
import { Dashboard } from "@app/screens/Admin/Dashboard";
import { GoogleRedirect } from "@app/screens/Admin/GoogleRedirect";
import { Games, GamesRoutes } from "@app/screens/Admin/Games";

// Layout Wrappers
import { LayoutAdmin } from "@app/screens/Admin/_Shared";
import { LayoutHomepage } from "@app/screens/Homepage/_Shared";
import { LayoutAuth } from "@app/screens/Auth/_Shared";

import { queryClient, useIsSignedIn } from "@app/utils/query";

import "@assets/styles/reset.css";
import "@assets/styles/utils.css";
import "@assets/styles/elements.css";
import "@assets/styles/styles.css";

// Redirect if not authed
const AuthedRoute = ({ children, redirectTo = "/login" }) => {
  const { data: isSignedIn } = useIsSignedIn();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSignedIn === false) navigate(redirectTo);
  }, [isSignedIn, navigate, redirectTo]);
  if (!isSignedIn) return null;
  return children;
};

const AUTHED_ROUTES = [
  {
    path: "dashboard",
    Component: <Dashboard />
  },
  {
    path: "google/redirect",
    Component: <GoogleRedirect />
  },
  {
    path: "credentials",
    Component: <Credentials />
  },
  {
    path: "games",
    Component: <Games />,
    SubRoutes: GamesRoutes // Sub-routes for /projects
  }
];

const App = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true
      }}
    >
      <Routes>
        <Route path="/" element={<LayoutHomepage />}>
          <Route path="*" element={<PageNotFound />} />
          <Route index element={<Home />} />
          <Route path="docs" element={<Docs />} />
          <Route path="docs/:title" element={<Docs />} />
          <Route path="terms" element={<Terms />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="privacy" element={<Privacy />} />
        </Route>
        <Route path="/" element={<LayoutAuth />}>
          <Route path="login" element={<Login />} />
          <Route path="exchange" element={<Exchange />} />
          <Route path="beta" element={<BetaSignup />} />
          <Route
            path="agreement"
            element={
              <AuthedRoute>
                <Agreement />
              </AuthedRoute>
            }
          />
        </Route>
        <Route path="/" element={<LayoutAdmin />}>
          {AUTHED_ROUTES.map(({ path, Component, SubRoutes }) => (
            <Route
              key={path}
              path={path}
              element={<AuthedRoute>{Component}</AuthedRoute>}
            >
              {SubRoutes && SubRoutes()}
            </Route>
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  </QueryClientProvider>
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
