import React from "react";
import { Outlet } from "react-router-dom";

import { Banner, Footer, Header, Sparkles } from ".";
import { Feedback } from "@app/components";

export const LayoutHomepage = () => {
  return (
    <>
      <div className="page-wrapper">
        <div className="page page--home page--blurred">
          <Sparkles />
          <div className="page-content">
            <Banner />
            <Header />
            <Outlet />
            <Footer />
          </div>
        </div>
      </div>
      <Feedback />
    </>
  );
};
