import React, { useState } from "react";
import { PAGES } from "@app/constants";
import { Button, Modal } from "@app/components";
import { EVENTS, trackEvent } from "@app/utils/trackEvent";
import {
  Apple,
  GodotLogoFull,
  Phone,
  Play,
  Smile
} from "@app/components/Icons";

import { Typing } from "./Typing";
import { QuickstartForm } from "./QuickstartForm";
import { MetaData } from "../_Shared";

const Home = () => {
  const [showEmailModal, setShowEmailModal] = useState(false);

  return (
    <main>
      <MetaData page={PAGES.Home} />
      <section className="hero">
        <div className="hero-left">
          <div className="hero-pre">
            <Play />
            <Phone />
            <Smile />
          </div>
          <h1 className="hero-h1">App Store ready Godot games in minutes.</h1>
          <p className="hero-p">
            Focus on building great games while we take care of getting them
            shipped to the App Store.{" "}
          </p>
          <div className="hero-buttons">
            <Button
              size="md"
              className="_mt10"
              variant="secondary"
              href="/docs/quickstart"
            >
              Quickstart
            </Button>
            <Button
              size="md"
              className="_mt10 hero-quickStartEmail"
              variant="secondary"
              onClick={() => {
                setShowEmailModal(true);
                trackEvent(EVENTS.QUICKSTART_MODAL_OPEN);
              }}
            >
              Email me the Quickstart guide.
            </Button>

            <Modal
              isShowing={showEmailModal}
              onClose={() => setShowEmailModal(false)}
            >
              <QuickstartForm />
            </Modal>

            <Button
              size="md"
              className="_mt10"
              variant="primary"
              href="https://npmjs.com/package/shipthis"
            >
              Get ShipThis
            </Button>
          </div>
        </div>
        <div className="hero-right">
          <Typing />
        </div>
      </section>

      <section className="features grid grid--2col">
        <div>
          <h2 className="features-headline">
            Cloud build for iOS without Xcode
          </h2>
          <p>ShipThis manages these for you: </p>

          <ul>
            <li>iOS certificates</li>
            <li>Provisioning profiles</li>
            <li>API keys</li>
          </ul>
          <p>
            With one command{" "}
            <span
              className="terminal emp-white"
              style={{
                padding: "3px 8px",
                borderRadius: 3
              }}
            >
              shipthis game ship
            </span>{" "}
            your game will be built on our servers and uploaded to the App
            Store.
          </p>
          <p>
            Your code always remains yours - ShipThis simply handles the heavy
            lifting of getting the end-result to the App Store.
          </p>
        </div>

        <div className="featureList-cloud1" />
      </section>

      <section className="features grid grid--2col">
        <div className="featureList-cloud2" />
        <div className="featureList-wrap">
          <h2 className="features-headline">ShipThis Features</h2>
          <ul className="featureList">
            <li>Guided Setup</li>
            <li>Build with one command</li>
            <li>Credentials are managed for you</li>
            <li>Monitor builds in the dashboard</li>
            <li>View error logs and build history</li>
            <li>Integrates with existing CI</li>
          </ul>
          <div className="_flex _flex-center _mt10">
            <Apple fill="white" className="_mr10" width={43} height={43} />
            <GodotLogoFull height={55} width={131} />
          </div>
        </div>
      </section>
    </main>
  );
};

export { Home };
